import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'antd/dist/antd.css'
import Listitems from './screens/List'
import Form from './screens/Form'
import Container from './Container'
import Login from './screens/Login'
import { auth } from './services'

const App = () => (
	<Router>
		<Switch>
			<Route path='/login' component={Login} />
			<Container>
				<PrivateRoute path='/list' component={Listitems} />
				<PrivateRoute path='/(add|edit)' component={Form} />
				<PrivateRoute path='/' exact component={Listitems} />
			</Container>
		</Switch>
	</Router>
)

const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props => auth.currentUser
			? <Component {...props} />
			: <Redirect to='/login' />
		}
	/>
)

export default App
