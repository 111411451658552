import React, { useState } from 'react'
import { Form, Input, Button, Row, Col, message } from 'antd'
import { auth } from '../services'
const Item = Form.Item

const Login = props => {
	const [isRegister, setIsRegister] = useState(true)

	const submit = async e => {
		e.preventDefault()
		props.form.validateFields(async (err, values) => {
			if (err) {
				message.error('Algo deu errado :/')
				return
			}
			try {
				const { name, email, password } = values
				const data = isRegister
					? await auth.createUserWithEmailAndPassword(email, password)
					: await auth.signInWithEmailAndPassword(email, password)

				if (!data.user.displayName) {
					auth.currentUser.updateProfile({ displayName: name })
				}

				props.history.push('/list')
			} catch (error) {
				console.log('error', error)
			}
		})

	}

	const { getFieldDecorator } = props.form

	return (
		<Row type='flex' justify='center' align='middle' style={{ height: '100vh' }}>
			<Col span={6}>
				<Form onSubmit={submit} className='login-form'>
					{isRegister && (
						<Item>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: 'Informe o nome!' }]
							})(
								<Input placeholder='Nome' />
							)}
						</Item>
					)}
					<Item>
						{getFieldDecorator('email', {
							rules: [{ required: true, message: 'Informe o email' }]
						})(
							<Input placeholder='Email' />
						)}
					</Item>
					<Item>
						{getFieldDecorator('password', {
							rules: [{ required: true, message: 'Informe a senha' }]
						})(
							<Input placeholder='Senha' />
						)}
					</Item>
					<Item>
						<Row type='flex' justify='space-between'>
							<Button type='primary' htmlType='submit' className='login-form-button'>
								{isRegister ? 'Registrar' : 'Entrar'}
							</Button>
							<Button
								type='dashed'
								onClick={() => setIsRegister(!isRegister)}
							>
								{`Ou ${isRegister ? ' entre ' : ' registre-se '} agora ;)`}
							</Button>
							<Button
								onClick={() => auth.signOut()}
							> sign out</Button>
						</Row>
					</Item>
				</Form>
			</Col>
		</Row>
	)
}

const WrappedNormalLoginForm = Form.create({ name: 'Login' })(Login)
export default WrappedNormalLoginForm
