import React, { useState, useEffect } from 'react'
import { List, Card, Icon, Popconfirm, message, Input } from 'antd'
import { StyleSheet, css } from 'aphrodite'
import { wordsRef } from '../services'

const ListItems = props => {
	const [list, setList] = useState([])
	const [inputSearch, setInputSearch] = useState('')

	useEffect(() => {
		const unsubscribe = getData()
		return () => {
			unsubscribe()
		}
	}, [])

	const getData = () => {
		const unsubscribe = wordsRef.onSnapshot(words => {
			const list = []
			words.forEach(item => {
				const { word, description } = item.data()
				list.push({ word, description, id: item.id })
			})

			setList(list)
		})

		return unsubscribe
	}

	const search = async value => {
		value = value.toLowerCase()
		const words = await wordsRef.get()

		if (words.empty) {
			message.info('Nenhum card encontrado :/')
			setInputSearch('')
			return 
		}

		const list = []
		words.forEach(item => {
			const { word, description } = item.data()
			if (word.toLowerCase().includes(value)) {
				list.push({ word, description, id: item.id })
			}
		})
		setList(list)
	}

	const remove = async id => {
		if (!id) return

		await wordsRef.doc(id).delete()
		message.success('Palavra removida com sucesso')
	}

	const update = async id => {
		if (!id) return
		props.history.push('/edit', { id })
	}

	const capitalizeIt = word => word.toString().replace(word[0], word[0].toUpperCase())

	return (
		<>
			<div className={css(styles.search)}>
				<Input
					value={inputSearch}
					placeholder='Faça sua busca aqui :)'
					prefix={<Icon type='search' className={css(styles.icon)} />}
					onPressEnter={e => search(e.target.value)}
					onChange={e => setInputSearch(e.target.value)}
				/>
			</div>
			<List
				grid={{ column: 4, gutter: 10 }}
				dataSource={list}
				renderItem={item => (
					<List.Item>
						<Card
							title={capitalizeIt(item.word)}
							className={css(styles.card)}
							actions={[
								<Icon
									type='edit'
									key='edit'
									onClick={() => update(item.id)}
								/>,
								<Popconfirm
									key='delete'
									title='Confirma deletar?'
									onConfirm={() => remove(item.id)}
									okText='Sim'
									cancelText='Não'
									placement='bottom'
								>
									<Icon type='delete' />
								</Popconfirm>
							]}
						>
							{item.description}
						</Card>
					</List.Item>
				)}
			/>
		</>
	)
}

const styles = StyleSheet.create({
	card: {
		width: 250,
		textAlign: 'center',
		boxShadow: '4px 4px 9px 1px #d2d2d2'
	},
	search: { 
		paddingBottom: '1em'
	},
	icon: {
		color: 'rgba(0,0,0,.25)'
	}
})

export default ListItems
