import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, message, Button, Alert, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { wordsRef, authorsRef } from '../services'
import get from 'lodash.get'
const FormItem = Form.Item
const Option = Select.Option


const FormAdd = props => {
	const [authorOptions, setAuthorOptions] = useState([])
	const [word, setWord] = useState()
	const [description, setDescription] = useState()
	const [author, setAuthor] = useState()
	const [id, setID] = useState()
	const [isSubmitting, setSubmitting] = useState(false)

	useEffect(() => {
		getFormData()
		getAuthors()
	}, [])

	const getAuthors = async () => {
		const authors = await authorsRef.get()
		const arr = []
		authors.forEach(author => {
			arr.push({
				name: author.data().name,
				id: author.id
			})
		})
		setAuthorOptions(arr)
	}

	const getFormData = async () => {
		const { id } = get(props, 'location.state', '')
		if (!id) return

		const form = await wordsRef.doc(id).get()
		if (!form.exists) return

		const { word, description, authorsRef } = form.data()

		if (authorsRef) {
			const res = await authorsRef.get()
			setAuthor(res.id)
		}

		setWord(word)
		setDescription(description)
		setID(id)
	}

	const submit = async e => {
		e.preventDefault()
		if (!isValid()) return

		setSubmitting(true)

		try {
			const form = {
				word,
				description,
				authorsRef: authorsRef.doc(author)
			}

			id
				? await wordsRef.doc(id).update(form)
				: await wordsRef.add(form)

			message.success('Palavra salva!')
			props.history.push('/list')
		} catch (error) {
			message.error('Ops, deu erro :(')
			console.log(error)
		}

		resetForm()
	}

	const resetForm = () => {
		setDescription()
		setWord()
		setAuthor()
		setSubmitting(false)
	}

	const isValid = () => {
		if (!word || !description)
			return false
		return true
	}

	const validate = word => word !== undefined ? word.length ? 'success' : 'error' : ''

	return (
		<>
			<Row type='flex' justify='center'>
				<Col span={8}>
					<Form layout='vertical' onSubmit={submit}>
						<FormItem
							label='Palavra'
							required
							hasFeedback={word !== undefined}
							validateStatus={validate(word)}
						>
							<Input
								placeholder='Exemplo: Vicissitude'
								onChange={e => setWord(e.target.value)}
								value={word}
								autoFocus
							/>
						</FormItem>
						<FormItem
							label='Descrição'
							validateStatus={validate(description)}
							hasFeedback={description !== undefined}
							required
						>
							<TextArea
								placeholder='Exemplo: Sequência de mudanças ou transformações'
								onChange={e => setDescription(e.target.value)}
								value={description}
							/>
						</FormItem>
						<FormItem
							label='Autor'
							validateStatus={validate(author)}
							hasFeedback={author !== undefined}
							required
						>
							<Select
								showSearch
								optionFilterProp='children'
								onChange={val => setAuthor(val)}
								value={author}
								filterOption={(input, { props }) => props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
								{authorOptions.map(x => (
									<Option value={x.id} key={x.id}>{x.name}</Option>
								))}
							</Select>
						</FormItem>
						<FormItem>
							<Button
								type='primary'
								htmlType='submit'
								disabled={!word || !description}
								block
								icon='check'
								loading={isSubmitting}
							>
								Cadastrar!
          		</Button>
						</FormItem>
					</Form>
				</Col>
			</Row>
			<Row type='flex' justify='center' style={{ marginTop: 60 }}>
				<Col span={14}>
					<HelperMessage />
				</Col>
			</Row>
		</>
	)
}

const HelperMessage = () => (
	<Alert
		message='Opa!'
		description={
			<span>Se está sem criatividade,
					<a href='https://www.normaculta.com.br/palavras-dificeis/' target='_tab'>dá uma olhada aqui</a>
			</span>
		}
		type='warning'
		showIcon
	/>

)

export default FormAdd